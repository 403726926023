import React from "react";

const DropdownTriangle = props => (
  <svg width="18px" height="14px" viewBox="0 0 18 14" {...props}>
    <defs>
      <linearGradient
        x1="49.9346171%"
        y1="83.6483123%"
        x2="49.9277105%"
        y2="0%"
        id="a"
      >
        <stop stopColor="#232D3A" offset="0%" />
        <stop stopColor="#0C52B7" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="115.119476%"
        y1="-86.1935556%"
        x2="55.9227775%"
        y2="-120.95206%"
        id="b"
      >
        <stop stopColor="#232D3A" offset="0%" />
        <stop stopColor="#0C52B7" offset="100%" />
      </linearGradient>
    </defs>
    <g
      transform="translate(-249 -3942)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <path fill="url(#a)" d="M0 0H320V4572H0z" />
      <g fill="url(#b)" transform="translate(-202 3746)">
        <path d="M330.857453 477.938308L477 122.113391 133.56 0.662264854z" />
        <path
          transform="translate(58)"
          d="M330.857453 477.938308L477 122.113391 133.56 0.662264854z"
        />
      </g>
      <g transform="translate(-202 3746) translate(232 187)">
        <path fill="#FFF" d="M0 0L247 0 247 33 0 33z" />
        <path
          d="M18.052 3.617l-6.242 9.269a2 2 0 01-3.318 0L2.25 3.617A2 2 0 013.909.5h12.484a2 2 0 011.659 3.117z"
          transform="translate(218 9)"
          fill="#E32C68"
        />
      </g>
    </g>
  </svg>
);

export default DropdownTriangle
