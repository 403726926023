import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import RemoveX from '../../svg/RemoveX'
import styles from './styles.module.css'
import DropdownTriangle from '../../svg/DropdownTriangle'

const cx = classNames.bind(styles)

const SelectedItem = ({ item, onClick }) => (
  <div className={styles.selectedItem}>
    <span>{item}</span>
    <RemoveX id={item} className={styles.remove} onClick={onClick}/>
  </div>
)

const Option = ({ item, onMouseOver, onClick, highlighted }) => (
  <li
    id={item}
    onClick={onClick}
    onMouseOver={onMouseOver}
    className={cx(styles.option, {[styles.highlighted]: highlighted})}>
    {item}
  </li>
)

class Selector extends Component {

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      highlighted: 0,
      selected: [],
    }
  }

  handleToggle = () => {
    this.setState(prev => ({
      open: !prev.open,
    }));
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  }

  navigateUp = () => {
    const len = this.props.options.length;
    const current = this.state.highlighted;
    const next = current > 0 ? current - 1 : len - 1
    this.setState({
      highlighted: next,
    });
  }

  navigateDown = () => {
    const len = this.props.options.length;
    const current = this.state.highlighted;
    const next = current < len - 1 ? current + 1 : 0
    this.setState({
      highlighted: next,
    });
  }

  handleRemove = item => {
    this.setState(prev => ({
      selected: prev.selected.filter(val => val !== item),
    }), () => {
      const { selected } = this.state;
      const { options } = this.props;
      const items = selected.map(index => options[index]);
      this.props.onChange(items);
    });
  };

  handleSelect = () => {
    this.setState(prev => ({
      selected: [...new Set(prev.selected).add(prev.highlighted)],
    }), () => {
      const { selected } = this.state;
      const { options } = this.props;
      const items = selected.map(index => options[index]);
      this.props.onChange(items);
    });
  };

  handleHover = item => {
    const { options } = this.props;
    this.setState({
      highlighted: options.indexOf(item),
    });
  }

  handleSelectClick = () => {
    this.handleSelect();
    this.handleClose();
  }

  handleKeyPress = evt => {
    if (!this.state.open) return;
    const { key } = evt;
    if (key === 'ArrowUp') {
      evt.preventDefault()
      this.navigateUp();
      return;
    }
    if (key === 'ArrowDown') {
      evt.preventDefault()
      this.navigateDown();
      return;
    }
    if (key === 'Enter') {
      this.handleSelect();
      this.handleClose();
      return;
    }
    if (key === 'Escape') {
      this.handleClose();
    }
  }

  render() {
    const { options, label } = this.props
    const { selected, open, highlighted } = this.state
    return (
      <div className={styles.container} onKeyDown={this.handleKeyPress} tabIndex={0}>
        <div className={styles.box} onClick={this.handleToggle}>
          {label}
          <DropdownTriangle/>
        </div>
        <div className={cx(styles.dropdown, { [styles.open]: open })}>
          <ul>
            {
              options.map(option =>
                <Option
                  item={option}
                  key={option}
                  onMouseOver={() => {
                    this.handleHover(option)
                  }}
                  onClick={this.handleSelectClick}
                  highlighted={highlighted === options.indexOf(option)}/>,
              )
            }
          </ul>
        </div>
        <div className={styles.selected}>
          {
            selected.map(index =>
              <SelectedItem
                item={options[index]}
                key={index}
                onClick={() => { this.handleRemove(index) }}/>,
            )
          }
        </div>
      </div>
    )
  }

}

Selector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

Selector.defaultProps = {}

export default Selector
