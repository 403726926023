import React, { Component } from 'react';
import Clients from '../Clients';
import Contact from '../Contact';
import Trapezoid from './Trapezoid';
import Triangle from './Triangle';
import styles from './styles.module.css';
import classNames from 'classnames';
require('intersection-observer')

const cx = classNames.bind(styles);

class BottomSection extends Component {

  constructor(props) {
    super(props);
    this.state = {
      animated: false,
    };
  }

  addClass = entries => {
    // if (entries[0].isIntersecting) {
    //   this.setState({
    //     animated: true,
    //   });
    // }
  };

  componentDidMount() {
    this.observer = new IntersectionObserver(this.addClass, { threshold: 0.5 });
    this.observer.observe(this.target);
  }

  render() {
    const { animated } = this.state;
    return (
      <div className={cx(styles.container, { [styles.animated]: animated })}>
        <div className={styles.polygons}>
          <Trapezoid className={styles.trapezoidLeft} gradient='bottomTrapezoidDarkBlue' />
          <Trapezoid className={styles.trapezoidRight} gradient='bottomTrapezoidBlue' />
          <Triangle className={styles.triangleLeft} />
          <Triangle className={styles.triangleRight} />
        </div>
        <Clients/>
        <div style={{zIndex:2}} ref={ref => {
          this.target = ref;
        }}>
          <Contact/>
        </div>
      </div>
    );
  }
}

export default BottomSection;
