import React from 'react'

const Trapezoid = props => (
  <svg viewBox="0 0 65 50" {...props}>
    <defs>
      <linearGradient id="pink" gradientTransform="rotate(50)">
        <stop offset="0%" stopColor="#B42856"/>
        <stop offset="100%" stopColor="#E32C68"/>
      </linearGradient>
    </defs>
    <polygon points="0 0 50 0 65 30 0 50" fill="url(#pink)" />
  </svg>
);

export default Trapezoid