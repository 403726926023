import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/Hero'
import Intro from '../components/Intro'
import Services from '../components/Services'
import About from '../components/About'
import BottomSection from '../components/BottomSection'

const IndexPage = () => (
  <Layout>
    <SEO title="Web and Mobile Development Services" description="Massbridge Software is a boutique software consultancy and shop. We focus on building software that creates lasting value for you and your customers. Our areas of expertise are web, mobile, and technical strategy."/>
    <Hero/>
    <Intro/>
    <Services/>
    <About/>
    <BottomSection/>
  </Layout>
)

export default IndexPage
