import React from 'react'
import styles from './styles.module.css'

const About = () => (
  <section className={styles.container} id='about'>
    <div className={styles.content}>
      <h2>About</h2>
      <p>
        Massbridge software is a boutique software consultancy and shop. We’re here because there are so many ways to do
        software wrong, and someone needs to do it right. Now, more than ever, software is critical for the success of a
        company. Helping your company succeed is our goal.
      </p>
      <p>
        Today, customers can tell the difference between high quality services, and everything else. We want to help
        your customers have a great experience, because we know what it’s like to use bad software. It sucks, and it
        doesn’t make for return customers or loyal users. That’s why we do this. We want to live in a world
        where people that make software care about the people using it. With our help, you’ll be part of that better
        world.
      </p>
    </div>
  </section>
)

export default About
