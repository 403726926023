import React from 'react'
import { navigate } from 'gatsby'
import styles from './styles.module.css'
import Button from '../Button'
import Triangle from './Triangle'
import Div100vh from 'react-div-100vh'
import {isMobile} from 'react-device-detect'

const Contents = () => (
  <div className={styles.pageContent}>
    <div className={styles.heading}>
      <div className={styles.spacer}/>
      <h1>Top-notch<br/>digital products</h1>
      <div className={styles.buttonWrapper}>
        <Button text="WORK WITH US" onClick={() => { navigate('#contact') }}/>
      </div>
    </div>
    <div className={styles.triangles}>
      <div>
        <Triangle className={`${styles.triangle} ${styles.up}`} gradient='blueInverted' style={{
          transform: `translateY(-25px)`
        }} />
        <Triangle className={`${styles.triangle} ${styles.down}`} gradient='blue' style={{
          transform: `translateY(25px) rotateZ(180deg)`
        }} />
        <Triangle className={`${styles.triangle} ${styles.up}`} gradient='blueInverted' style={{
          transform: `translateY(-70px)`
        }} />
        <Triangle className={`${styles.triangle} ${styles.down}`} gradient='blue' style={{
          transform: `translateY(25px) rotateZ(180deg)`
        }} />
        <Triangle className={`${styles.triangle} ${styles.up}`} gradient='blueInverted' style={{
          transform: `translateY(-75px) `
        }} />
        <Triangle className={`${styles.triangle} ${styles.down}`} gradient='pink' style={{
          transform: `translateY(-20px) rotateZ(180deg)`
        }} />
      </div>
    </div>
  </div>
)

const Hero = () => {
  if (isMobile) {
    return (
      <Div100vh className={styles.container}>
        {Contents()}
      </Div100vh>
    )
  }
  return (
    <div className={styles.container}>
      {Contents()}
    </div>
  )
}


export default Hero
