import React from 'react'

const Triangle = props => (
  <svg viewBox="0 0 70 70" {...props}>
    <defs>
      <linearGradient id="pink" gradientTransform="rotate(180)">
        <stop offset="0%" stopColor="#B42856"/>
        <stop offset="100%" stopColor="#E32C68"/>
      </linearGradient>
    </defs>
    <polygon points="0 0 70 0 70 70" fill="url(#pink)" />
  </svg>
);

export default Triangle