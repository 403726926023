import React from "react"

const Button = props => (
  <button {...props} style={{display: 'flex', border: 0, padding: 0, margin: 0}}>
  <svg width="173px" height="49px" viewBox="0 0 173 49" style={{cursor: 'pointer'}}>
    <defs>
      <linearGradient
        x1="44.9988355%"
        y1="46.4664878%"
        x2="44.7082956%"
        y2="0%"
        id="a"
      >
        <stop stopColor="#232D3A" offset="0%" />
        <stop stopColor="#0C52B7" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="115.030472%"
        y1="-86.1935556%"
        x2="55.9146824%"
        y2="-120.95206%"
        id="b"
      >
        <stop stopColor="#232D3A" offset="0%" />
        <stop stopColor="#0C52B7" offset="100%" />
      </linearGradient>
    </defs>
    <g
      transform="translate(-49 -3723)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <path
        fill="url(#a)"
        d="M0 2342L1024 2471.75708 1024 4052 0 4046.67219z"
      />
      <g fill="url(#b)" transform="translate(-193 3095)">
        <path d="M594.462965 1438.72344L1034.61355 366.320586 0.244573741 0.285134568z" />
        <path
          transform="translate(158.973)"
          d="M594.462965 1438.72344L1034.61355 366.320586 0.244573741 0.285134568z"
        />
      </g>
      <g transform="translate(-193 3095) translate(241 156) translate(1 464) translate(0 8)">
        <path fill="#E32C68" d="M0 0L173 0 173 49 0 49z" />
        <path fill="#B42856" d="M161 0L173 0 173 49 161 49z" />
        <text
          fontFamily="Roboto Slab"
          fontSize={16}
          fontWeight="bold"
          letterSpacing={0.15}
          fill="#FFF"
        >
          <tspan x={24} y={30}>
            {props.text}
          </tspan>
        </text>
      </g>
    </g>
  </svg>
  </button>
);

export default Button

/*
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import lottie from 'lottie-web'
import animationData from '../animations/sendButton'

let animation = null

class SendButton extends Component {

  componentDidMount() {
    animation = lottie.loadAnimation({
      container: this.container,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData,
    })
  }

  componentWillUnmount() {
    animation.destroy()
  }

  play = () => {
    animation.setDirection(1);
    animation.setSpeed(1);
    animation.play();
  }

  reset = () => {
    animation.setDirection(-1);
    animation.setSpeed(2);
    animation.play();
  }

  render() {
    return (
      <div
        style={{width: '100%', maxWidth: 400}}
        onMouseOver={this.play}
        onMouseLeave={this.reset}
        onClick={this.props.onClick}
        ref={ref => {
          this.container = ref
        }} />
    )
  }
}

SendButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default SendButton*/
