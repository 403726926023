import React from 'react'

const Triangle = props => (
  <svg viewBox="0 0 200 200" {...props}>
    <defs>
      <linearGradient id="introBlue" gradientTransform="rotate(20)">
        <stop offset="0%" stopColor="#0C52B7"/>
        <stop offset="100%" stopColor="#1C8ADC"/>
      </linearGradient>
    </defs>
    <polygon points="0 0 200 0 200 200" fill="url(#introBlue)" />
  </svg>
);

export default Triangle