import React from 'react'

const Trapezoid = props => (
  <svg viewBox="0 0 80 120" {...props}>
    <defs>
      <linearGradient id="bottomTrapezoidBlue" gradientTransform="rotate(-73)">
        <stop offset="0%" stopColor="#193d6f"/>
        <stop offset="89%" stopColor="#1f334e"/>
      </linearGradient>
      <linearGradient id="bottomTrapezoidDarkBlue" gradientTransform="rotate(-73)">
        <stop offset="89%" stopColor="#193d6f"/>
        <stop offset="0%" stopColor="#232d3a"/>
      </linearGradient>
    </defs>
    <polygon points="0 0 80 0 20 120 0 100" fill={`url(#${props.gradient})`}/>
  </svg>
);

export default Trapezoid