import React from 'react'
import styles from './styles.module.css'
import ContactForm from './ContactForm'

const Contact = () => (
  <section className={styles.container} id='contact'>
    <div className={styles.pageContent}>
      <div className={styles.content}>
        <h2>Contact</h2>
        <p className={styles.contentBody}>Whether you need to build a new product or get some help with a project you already have in the works, get in
          touch and we’ll figure out exactly what you need</p>
        <ContactForm/>
      </div>
    </div>
  </section>
)

export default Contact
