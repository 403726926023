import React from "react";

const RemoveX = props => (
  <svg width="12px" height="11px" viewBox="0 0 12 11" {...props}>
    <defs>
      <linearGradient
        x1="49.9346171%"
        y1="83.6483123%"
        x2="49.9277105%"
        y2="0%"
        id="a"
      >
        <stop stopColor="#232D3A" offset="0%" />
        <stop stopColor="#0C52B7" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="115.119476%"
        y1="-86.1935556%"
        x2="55.9227775%"
        y2="-120.95206%"
        id="b"
      >
        <stop stopColor="#232D3A" offset="0%" />
        <stop stopColor="#0C52B7" offset="100%" />
      </linearGradient>
    </defs>
    <g
      transform="translate(-154 -3984)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <path fill="none" d="M0 0H320V4572H0z" />
      <g fill="none" transform="translate(-202 3746)">
        <path d="M330.857453 477.938308L477 122.113391 133.56 0.662264854z" />
        <path
          transform="translate(58)"
          d="M330.857453 477.938308L477 122.113391 133.56 0.662264854z"
        />
      </g>
      <g transform="translate(-202 3746) translate(232 234)">
        <path fill="#E32C68" d="M0 0H142V19H0z" />
        <path
          d="M131 3v5.5h5.5v2H131V16h-2v-5.5h-5.5v-2h5.5V3h2z"
          fill="#FFF"
          transform="rotate(45 130 9.5)"
        />
      </g>
    </g>
  </svg>
);

export default RemoveX;
