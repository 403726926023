import React, { Component } from 'react'
import styles from './styles.module.css'
import classNames from 'classnames'
import Triangle from './Triangle'
require('intersection-observer')

const cx = classNames.bind(styles)

class Intro extends Component {

  constructor(props) {
    super(props);
    this.state = {
      animated: false
    };
  }

  addClass = entries => {
    // if(entries[0].isIntersecting) {
    //   this.setState({
    //     animated: true
    //   });
    // }
  }

  componentDidMount() {
    this.observer = new IntersectionObserver(this.addClass, { threshold: 0.5 });
    this.observer.observe(this.target);
  }

  render() {
    const { animated } = this.state;
    return (
      <section className={styles.container} ref={ref => { this.target = ref }}>
        <div className={styles.pageContent}>
          <div className={cx(styles.triangles, {[styles.animated]: animated})} >
            <Triangle className={styles.triangle} />
            <Triangle className={styles.triangle} />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.heading}>
            <h2>Software development is&nbsp;complicated</h2>
          </div>
          <p>
            Finding someone to do it shouldn’t be. We can jump in anywhere your project needs help. Don’t know where to
            start? We’ll help with strategy and architecture. In the middle of a pile of spaghetti code and don’t know
            how
            to unravel the mess? We excel where others have failed. Do you need to be sure everything is getting
            deployed
            correctly? We’ll help you push it over the finish line.
          </p>
        </div>

      </section>
    )
  }
}

export default Intro
