import React from 'react'

const Triangle = props => (
  <svg viewBox="0 0 200 500" {...props}>
      <defs>
        <linearGradient id="pink" gradientTransform="rotate(50)">
          <stop offset="0%" stopColor="#E32C68"/>
          <stop offset="100%" stopColor="#B42856"/>
        </linearGradient>
        <linearGradient id="blue" gradientTransform="rotate(90)">
          <stop offset="0%" stopColor="#1C8ADC"/>
          <stop offset="80%" stopColor="#0C52B7"/>
        </linearGradient>
        <linearGradient id="blueInverted" gradientTransform="rotate(90)">
          <stop offset="0%" stopColor="#0C52B7"/>
          <stop offset="100%" stopColor="#1C8ADC"/>
        </linearGradient>
      </defs>
      <polygon points="0 80 0 500 200 0" fill={`url(#${props.gradient})`}/>
  </svg>
);

export default Triangle