import React from 'react'

const Triangle = props => (
  <svg viewBox="0 0 110 110" {...props}>
    <defs>
      <linearGradient id="bottomTriangleBlue" gradientTransform="rotate(0)">
        <stop offset="0%" stopColor="#0c52b7"/>
        <stop offset="89%" stopColor="#232d3a"/>
      </linearGradient>
    </defs>
    <polygon points="0 0 110 0 110 110" fill="url(#bottomTriangleBlue)"/>
  </svg>
);

export default Triangle