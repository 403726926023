import React, { Component } from 'react'
import styles from './styles.module.css'
import classNames from 'classnames'
import Triangle from './Triangle'
import Trapezoid from './Trapezoid'
import TrapezoidLong from './TrapezoidLong'
require('intersection-observer')

const cx = classNames.bind(styles)

class Services extends Component {

  constructor(props) {
    super(props);
    this.state = {
      animated: false
    };
  }

  addClass = entries => {
    // if(entries[0].isIntersecting) {
    //   this.setState({
    //     animated: true
    //   });
    // }
  }

  componentDidMount() {
    this.observer = new IntersectionObserver(this.addClass, { threshold: 0.5 });
    this.observer.observe(this.target);
  }

  render() {
    const { animated } = this.state;
    return (
      <div className={cx(styles.wrapper, { [styles.animated]: animated })} ref={ref => { this.target = ref }}>
        <Triangle className={styles.triangle}/>
        <section className={styles.container} id='services'>
          <div className={styles.content}>
            <h2 className={styles.heading}>Services</h2>
            <p>
              We focus on building software that creates value for you and your customers. That’s why our implementation
              techniques are custom fitted to your needs
            </p>
            <div className={styles.services}>
              <div>
                <h3>mobile development</h3>
                <p>
                  We build first-class mobile apps for iOS and Android. Our team has experience delivering both native
                  and hybrid applications.
                </p>
              </div>
              <div>
                <h3>web development</h3>
                <p>
                  From the UI layer down to the data layer, we’ve got you covered. Our team ships rich, scalable
                  experiences for the web. If you can dream it up, we can build it.
                </p>
              </div>
              <div>
                <h3>technical strategy</h3>
                <p>
                  Product development needs strategic thinking at every level. Cost-benefit analysis will help keep
                  things in
                  budget and sustainable for future upgrades and maintenance. We build things with longevity and fault
                  tolerance in mind.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.polygons}>
            <div>
              <Trapezoid className={styles.trapezoid} />
              <Trapezoid className={styles.trapezoid} />
              <Trapezoid className={styles.trapezoid} />
              <Trapezoid className={styles.trapezoid} />
            </div>
            <div>
              <TrapezoidLong className={styles.trapezoidLong} />
              <TrapezoidLong className={styles.trapezoidLong} />
              <TrapezoidLong className={styles.trapezoidLong} />
              <TrapezoidLong className={styles.trapezoidLong} />
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default Services
