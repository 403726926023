import React from 'react'

const TrapezoidLong = props => (
  <svg viewBox="0 0 50 120" {...props}>
    <defs>
      <linearGradient id="pink" gradientTransform="rotate(50)">
        <stop offset="0%" stopColor="#B42856"/>
        <stop offset="100%" stopColor="#E32C68"/>
      </linearGradient>
    </defs>
    <polygon points="0 0 50 0 20 120 0 100" fill="url(#pink)" />
  </svg>
);

export default TrapezoidLong