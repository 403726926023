import React from 'react'
import styles from './styles.module.css'
import Boomy from '../svg/Boomy'
import Waam from '../svg/Waam'
import Priceflow from '../svg/Priceflow'

const Clients = () => (
  <section className={styles.container} id='clients'>
    <div className={styles.content}>
      <h2 className={styles.clientHeaderHelper}>Clients</h2>
      <div className={styles.clients}>
        <blockquote className={`${styles.client} ${styles.clientLink}`} onClick={() => window.open('https://apps.apple.com/us/app/waam-app/id1483635996', '_blank') }>
          <Waam className={styles.logo} />
          <div className={styles.quotes}>“ ”</div>
          <p className={styles.testimonial}>
            Working with Massbridge has been the best experience I have ever had working with a dev shop. Our company wouldn't be where it is today without them.
          </p>
          <div className={styles.dash}>-</div>
          <cite className={styles.name}>Karly Pavlinac</cite>
          <cite className={styles.company}>WAAM</cite>
        </blockquote >
        <blockquote className={`${styles.client} ${styles.clientLink}`} onClick={() => window.open('https://boomy.com', '_blank') }>
          <Boomy className={styles.boomyLogo} />
          <div className={styles.quotes}>“ ”</div>
          <p className={styles.testimonial}>
            The Massbridge team is fast, thorough, and honest. Their attention to detail and unusually high degree of user empathy makes them ideal partners for actualizing ambitious software vision.
          </p>
          <div className={styles.dash}>-</div>
          <cite className={styles.name}>Alex Mitchell</cite>
          <cite className={styles.company}>Boomy</cite>
        </blockquote>
        <blockquote className={styles.client}>
          <Priceflow className={styles.logo} />
          <div className={styles.quotes}>“ ”</div>
          <p className={styles.testimonial}>
            From start to finish they were a pleasure to work with, consistently delivering high quality results.
          </p>
          <div className={styles.dash}>-</div>
          <cite className={styles.name}>Matt Mullens</cite>
          <cite className={styles.company}>Priceflow</cite>
        </blockquote>
      </div>
    </div>
  </section>
)

export default Clients
