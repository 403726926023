import React, { Component } from 'react';
import styles from './styles.module.css';
import Selector from './Selector';
import Button from '../Button';
import { Formik } from 'formik';
import * as yup from 'yup';

const OPTIONS = [
  'mobile development',
  'web development',
  'technical strategy',
];

class ContactForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      services: [],
      name: '',
      message: '',
      email: '',
      submitted: false
    };
  }

  handleChange = evt => {
    const { name, value } = evt.target;
    evt.preventDefault();
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = selected => {
    this.setState({
      services: selected,
    });
  };

  handleSendClick = (values, {setSubmitting}) => {
    const { name, message, email } = values;
    const { services } = this.state;
    const body = JSON.stringify({ services: services.join(","), name, email, message });
    const url = `https://script.google.com/macros/s/AKfycbwpbh2ki6usbH3tykSoQJKsb1ZlKyCrTqgrS_LZn6rIAVs4o4c/exec?services=${services}&name=${name}&email=${email}&message=${message}`;
    const xhr = new XMLHttpRequest();
    xhr.addEventListener('load', () => {
      // update the state of the component with the result here
      if (xhr.status === 200) {
        this.setState({submitted: true});
      }
      setSubmitting(false);
    });

    xhr.open('GET', url);
    // send the request
    xhr.send(body);
  };

  render() {
    const { name, message, email, submitted } = this.state;
    if (submitted) {
      return (
        <p className={styles.submitted}>Thanks for contacting us! We'll be in touch soon.</p>
      )
    }
    return (
      <Formik
        initialValues={{ name, message, email }}
        validationSchema={yup.object().shape({
          name: yup.string().required(),
          email: yup.string().email().required(),
          message: yup.string().required()
        })}
        onSubmit={this.handleSendClick}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form className={styles.form} onSubmit={handleSubmit}>
              <Selector
                options={OPTIONS}
                label='What service(s) would you like to discuss?'
                onChange={this.handleSelectChange}/>
              <label htmlFor='name'>name</label>
              <input
                className={touched && !errors.name ? styles.valid : styles.invalid}
                type='text'
                id='name'
                name='name'
                value={values.name}
                onBlur={handleBlur}
                onChange={handleChange}/>
              <p className={styles.error}>{errors.name && touched.name && errors.name}</p>
              <label htmlFor='email'>email</label>
              <input
                className={touched && !errors.email ? styles.valid : styles.invalid}
                id='email'
                name='email'
                type='email'
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}/>
              <p className={styles.error}>{errors.email && touched.email && errors.email}</p>
              <label htmlFor='message'>message</label>
              <textarea
                className={touched && !errors.message ? styles.valid : styles.invalid}
                id='message'
                name='message'
                value={values.message}
                onBlur={handleBlur}
                onChange={handleChange}/>
              <p className={styles.error}>{errors.message && touched.message && errors.message}</p>
              <div className={styles.buttonWrapper}>
                <Button type="submit" disabled={isSubmitting} text={isSubmitting ? 'submitting...' : 'SEND MESSAGE'}/>
              </div>
            </form>
        )}
      </Formik>
    );
  }
}

export default ContactForm;
